import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { LayoutProvider } from "../contexts/layout";
import PrivateRoute from "./PrivateRoute";
import Layout from "../components/Layout";
import SignUpPage from "../pages/SignUpPage/SignUpPage";
import SuccessPage from "../pages/SuccessPage";
import FailurePage from "../pages/FailurePage";
import NotFoundPage from "../pages/NotFoundPage";
import VerifyPage from "../pages/VerifyPage";

export default function MainRouter() {
  return (
    <HashRouter>
      <LayoutProvider initialContent={{ footer: "Shoper-xSale 2021©" }}>
        <Layout>
          <Switch>
            <PrivateRoute
              path="/signUp"
              component={SignUpPage}
              rejectionPath="/verify"
            />
            <Route path="/verify" component={VerifyPage} />
            <Route path="/failure" component={FailurePage} />
            <Route path="/success" component={SuccessPage} />
            <Route path="/notFound" component={NotFoundPage} exact />
            <Route path="/" exact render={() => <Redirect to="/failure" />} />
            <Route path="*" render={() => <Redirect to="/notFound" />} />
          </Switch>
        </Layout>
      </LayoutProvider>
    </HashRouter>
  );
}
