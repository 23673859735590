export default function Header({ className, children }) {
  return (
    <header
      className={`w-full flex pt-7 px-5 justify-center items-center text-gray-700 font-header text-2xl text-center ${
        className ?? ""
      }`}
    >
      {children}
    </header>
  );
}
