const TermsAgreementsLabel = ({ targetUrl }) => (
  <span className="inline-block align-text-top text-center">
    Akceptuję warunki{" "}
    <a
      className="underline text-primary opacity-70 hover:opacity-100"
      target="_blank"
      rel="noreferrer"
      href={targetUrl}
    >
      regulaminu
    </a>{" "}
    korzystania z usługi
  </span>
);

export default TermsAgreementsLabel;
