import { useLayoutState } from "../contexts/layout";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

export default function Layout({ children }) {
  const [{ header, footer }] = useLayoutState();
  return (
    <div className="min-h-screen bg-gray-50 w-full relative flex flex-col justify-between items-center box-border font-bod">
      <Header>{header}</Header>
      <Content className="mx-auto max-w-4xl flex-1 flex justify-center flex-col items-center p-4">
        {children}
      </Content>
      <Footer>{footer}</Footer>
    </div>
  );
}
