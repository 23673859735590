export const getUrlQueryParams = (query) =>
  Object.fromEntries(
    query
      ?.replace("?", "")
      .split("&")
      .map((paramEntry) => paramEntry?.split("="))
  );

export const isObject = (value, includeArray = true) =>
  typeof value === "object" &&
  value !== null &&
  (includeArray || !Array.isArray(value));

export const trim = (data) =>
  Object.entries(data).reduce((trimedObj, [key, value]) => {
    trimedObj[key] = typeof value === "string" ? value.trim() : value;
    return trimedObj;
  }, {});

export const objectToQuery = (params) =>
  Object.entries(params)?.reduce(
    (query, [key, value], index, arr) =>
      query +
      `${key}=${isObject(value) ? JSON.stringify(value) : value}${
        index !== arr.length - 1 ? "&" : ""
      }`,
    "?"
  );
