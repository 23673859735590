import { useEffect } from "react";
import { useLayoutState } from "../contexts/layout";
import { ReactComponent as NotFoundSVG } from "../static/icons/not-found.svg";
export default function NotFoundPage() {
  const [, { setHeader }] = useLayoutState();
  useEffect(() => {
    setHeader(null);
  }, []);
  return (
    <div>
      <NotFoundSVG width="100%" />
      <p className="text-gray-400 text-2xl text-center p-6 font-bold">
        Nie znaleziono strony
      </p>
    </div>
  );
}
