import { forwardRef } from "react";
export default forwardRef(
  (
    {
      label,
      type,
      id,
      onFocus,
      onBlur,
      className,
      inputClassName,
      labelClassName,
      labelRef,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className ?? "input-wrapper"}>
        <label
          className={`input-label ${labelClassName ?? ""}`}
          ref={labelRef}
          htmlFor={id}
        >
          {typeof label === "function" ? label() : label}
        </label>
        <input
          className={inputClassName}
          type={type}
          id={id}
          {...props}
          onFocus={onFocus ? onFocus : () => undefined}
          onBlur={onBlur ? onBlur : () => undefined}
        />
        {children}
      </div>
    );
  }
);
