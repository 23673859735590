import { useReducer, createContext, useContext, useMemo } from "react";

const store = createContext();

export const useStore = () => useContext(store);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialStore);
  const actions = useMemo(() => getActions(dispatch), []);
  const providerValue = useMemo(() => [state, actions], [state, actions]);
  return <store.Provider value={providerValue}>{children}</store.Provider>;
};

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  FAILURE: "failure",
  INITIAL: "initial",
  LOADING: "loading",
  VERIFIED: "verified",
});

const ACTIONS = Object.freeze({
  LOADING: "loading",
  VERIFY: "verify",
  FAIL: "fail",
  SUCCESS: "success",
  RESET: "reset",
  CLEAR_ERRORS: "clearErrors",
  MARK_ERROR_AS_DISPLAYED: "markErrorAsDisplay",
});

const initialStore = Object.freeze({
  errors: {},
  authData: null,
  status: STATUSES.INITIAL,
});

const getActions = (dispatch) =>
  Object.freeze(
    Object.values(ACTIONS).reduce((accActions, action) => {
      accActions[action] = (payload) => dispatch({ type: action, payload });
      return accActions;
    }, {})
  );

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING:
      return { ...state, status: STATUSES.LOADING };
    case ACTIONS.VERIFY:
      const { isVerified, errorMessage, authData } = action.payload || {};
      return isVerified
        ? { ...state, status: STATUSES.VERIFIED, authData }
        : {
            ...state,
            status: STATUSES.FAILURE,
            errors: {
              ...state.errors,
              verify: {
                displayed: false,
                message: errorMessage ?? "Weryfikacja nie powiodła się",
              },
            },
          };
    case ACTIONS.FAIL:
      return {
        ...state,
        status: STATUSES.FAILURE,
        errors: { ...state.errors, ...action.payload.errors },
      };
    case ACTIONS.SUCCESS:
      return { ...state, status: STATUSES.SUCCESS };
    case ACTIONS.RESET:
      return initialStore;
    case ACTIONS.CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };
    case ACTIONS.MARK_ERROR_AS_DISPLAYED:
      const key = action.payload.errorKey;
      return {
        ...state,
        errors:
          key in state.errors && !state.errors[key]?.displayed
            ? {
                ...state.errors,
                [key]: { ...state.errors[key], displayed: true },
              }
            : state.errors,
      };
    default:
      return state;
  }
};
