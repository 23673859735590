import { forwardRef } from "react";

const OptionCard = forwardRef(
  ({ children, value, labelRef, targetUrl, ...props }, ref) => {
    const id = `${value}-id`;
    return (
      <label ref={labelRef} className="plan-card relative" htmlFor={id}>
        {children}
        <div className="text-center flex flex-col items-center justify-center absolute bottom-6 left-0 w-full">
          {targetUrl ? (
            <a
              className="underline text-primary opacity-70 hover:opacity-100 pb-2"
              href={targetUrl}
            >
              szczegóły
            </a>
          ) : null}
          <input
            id={id}
            value={value}
            className="cursor-pointer"
            {...props}
            ref={ref}
            type="radio"
          />
        </div>
      </label>
    );
  }
);

OptionCard.Content = ({ headerText, children, price }) => (
  <div>
    <header className="pb-6">
      <p className="text-center font-header pb-2 text-2xl text-primary">
        {headerText}
      </p>
      <p className="text-center text-gray-500">od {price} pln/msc</p>
    </header>
    <ul className="text-lg text-center text-gray-700 pb-3">{children}</ul>
  </div>
);

OptionCard.Item = ({ children }) => <li className="pb-3">{children}</li>;

export default OptionCard;
