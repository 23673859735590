import { useEffect } from "react";
import { useLocation } from "react-router";
import { useStore } from "../contexts/store";
import { useLayoutState } from "../contexts/layout";
import { ReactComponent as FailureSVG } from "../static/icons/failure.svg";
import { ERROR_CODES } from "../static/constants";

export default function FailurePage() {
  const [{ errors }, actions] = useStore();
  const { state } = useLocation();
  const [, { setHeader }] = useLayoutState();
  useEffect(() => {
    setHeader("Coś poszło nie tak");
    return () => actions.reset();
  }, []);

  return (
    <div>
      <FailureSVG className="mx-auto" width="80%" height="50%" />
      <p className="text-gray-400 text-2xl text-center p-6">
        {errors?.[state?.errorKey]?.message ?? ERROR_CODES.VERIFY_FAILURE}
      </p>
    </div>
  );
}
