import axios from "axios";
import { objectToQuery } from "../commons/utils";

const DEFAULT_HEADERS = Object.freeze({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
});

class Api {
  constructor(baseURL = "https://shoperbe.vicoop.com") {
    this.client = axios.create({
      baseURL,
      headers: DEFAULT_HEADERS,
    });
  }

  verify(authData) {
    const request = () =>
      this.client.get(this._getUrl("/verify", authData), this._getHeaders());
    return this._handleRequest(request);
  }

  sendForm(formData, authData) {
    const request = () =>
      this.client.post(
        "/register",
        { formData, authData },
        {
          headers: this._getHeaders(),
        }
      );
    return this._handleRequest(request);
  }

  _getUrl(route = "/", params) {
    return `${route}${params ? objectToQuery(params) : ""}`;
  }

  _getHeaders(params = {}) {
    return { ...DEFAULT_HEADERS, ...params };
  }

  async _handleRequest(request) {
    try {
      const { data, status } = await request();
      if (status === 200) {
        return [data, undefined];
      }
      throw Error(`Request error with status code: ${status}`);
    } catch (error) {
      return [undefined, error?.response?.data || error];
    }
  }
}

export default new Api();
