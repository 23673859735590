import { StoreProvider } from "./contexts/store";
import MainRouter from "./routers/MainRouter";
function App() {
  return (
    <StoreProvider>
      <div className="App">
        <MainRouter />
      </div>
    </StoreProvider>
  );
}

export default App;
