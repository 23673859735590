import { css } from "@emotion/react";
import { HashLoader as Loader } from "react-spinners";

const override = css`
  display: block;
`;

export default function FullScreenLoadingIndicator({ loading }) {
  return (
    loading && (
      <div className="w-full h-full flex items-center justify-center">
        <Loader color="blue" loading={loading} css={override} size={140} />
      </div>
    )
  );
}
