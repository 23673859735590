import { Route, Redirect, useLocation } from "react-router-dom";
import { useStore, STATUSES } from "../contexts/store";

export default function PrivateRoute({
  rejectionPath,
  saveSearchQuery = true,
  ...props
} = {}) {
  const [{ status }] = useStore();
  const { search } = useLocation();

  return status !== STATUSES.INITIAL ? (
    <Route {...props} />
  ) : (
    <Redirect to={`${rejectionPath}${saveSearchQuery ? search : ""}` ?? "/"} />
  );
}
