import { useMemo, useEffect, useCallback } from "react";
import { useLocation, Redirect } from "react-router";
import { useStore, STATUSES } from "../contexts/store";
import { useLayoutState } from "../contexts/layout";
import { getUrlQueryParams } from "../commons/utils";
import api from "../services/api";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";

export default function VerifyPage() {
  const [{ status }, actions] = useStore();
  const [, { setHeader }] = useLayoutState();
  const { search } = useLocation();
  const queryParams = useMemo(() => getUrlQueryParams(search), [search]);
  const { INITIAL, LOADING, VERIFIED, SUCCESS } = STATUSES;

  const verify = useCallback(
    async () => {
      actions.loading();
      const [data] = await api.verify(queryParams);
      actions.verify({
        isVerified: !!data?.isVerified,
        authData: queryParams,
      });
    },
    [actions, queryParams]
  );

  useEffect(() => {
    verify();
  }, [verify]);

  useEffect(() => {
    setHeader("Weryfikacja użytkownika");
  }, []);

  switch (status) {
    case VERIFIED:
      return <Redirect to={`/signUp${search}`} />;
    case SUCCESS:
      return <Redirect push to={`/success`} />;
    case LOADING:
      return <FullScreenLoadingIndicator loading={true} />;
    case INITIAL:
      return null;
    default:
      return (
        <Redirect
          to={{ pathname: "/failure", state: { errorKey: "verify" } }}
        />
      );
  }
}
