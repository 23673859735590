import { useEffect } from "react";
import { Redirect } from "react-router";
import { useStore, STATUSES } from "../contexts/store";
import { useLayoutState } from "../contexts/layout";
import { ReactComponent as SuccessSVG } from "../static/icons/success.svg";
export default function SuccessPage() {
  const [{ status }] = useStore();
  const [, { setHeader }] = useLayoutState();
  useEffect(() => {
    setHeader(null);
  }, []);
  return status === STATUSES.SUCCESS ? (
    <div>
      <SuccessSVG width="100%" />
      <p className="text-gray-400 text-2xl text-center p-6 font-bold">
        Rejestracja zakończona sukcesem!
      </p>
    </div>
  ) : (
    <Redirect to="/failure" />
  );
}
