import { memo, useRef } from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import OptionCard from "./OptionCard";
import Input from "./Input";
import TermsAgreementsLabel from "./TermsAgreementLabel";
import { trim } from "../../commons/utils";

const validationMessages = Object.freeze({
  password:
    "Hasło musi mieć od 6 do 32 znaków - co najmniej 1 cyfrę, 1 małą i 1 dużą literę",
  confirmPassword: "Hasła muszą być identyczne",
  email: "Email musi zawierać '@' oraz mieć od 3 do 48 znaków",
  organizationName: "Nazwa firmy musi mieć od 2 do 64 znaków",
  plan: "Wybór pakietu jest wymagany",
  termsAgreement: "Musisz akceptować regulamin",
});

export default memo(function SignUpPage({ sendForm }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm();

  const activeLabelRefs = useRef({});

  const setRef = (node) => (activeLabelRefs.current[node?.htmlFor] = node);

  const addLabelClass = (e) =>
    activeLabelRefs.current[e.target.id]?.classList.add("active-label");

  const removeLabelClass = (e) =>
    activeLabelRefs.current[e.target.id]?.classList.remove("active-label");

  const toggleCardClass = (e) =>
    Object.values(activeLabelRefs.current).forEach((label) =>
      e.target.id === label?.htmlFor
        ? label?.classList.toggle("active-card")
        : label?.classList.remove("active-card")
    );

  const onSubmit = (data) => sendForm(trim(data));

  const handleAgreementCheckboxChange = (e) => {
    e.target.checked && clearErrors("termsAgreement");
    return (e.target.value = e.target.checked ? "on" : "off");
  };

  return (
    <form
      noValidate
      className="flex flex-col items-center justify-between pt-6 pb-9 lg:p-12"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full flex flex-col">
        <div className="inputs-wrapper">
          <Input
            id="email-input"
            type="email"
            label="Email"
            placeholder="podaj swój email..."
            inputClassName="text-input"
            labelRef={setRef}
            {...register("email", {
              required: true,
              minLength: 3,
              maxLength: 48,
              pattern:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
            onFocus={addLabelClass}
            onBlur={removeLabelClass}
          >
            <span className="validation-error-message">
              {errors.email && validationMessages.email}
            </span>
          </Input>
          <Input
            inputClassName="text-input"
            labelRef={setRef}
            placeholder="podaj nazwę swojej firmy..."
            label="Nazwa firmy"
            type="text"
            id="organizationName-input"
            {...register("organizationName", {
              required: true,
              minLength: 2,
              maxLength: 64,
            })}
            onFocus={addLabelClass}
            onBlur={removeLabelClass}
          >
            <span className="validation-error-message">
              {errors.organizationName && validationMessages.organizationName}
            </span>
          </Input>
        </div>
        <div className="inputs-wrapper">
          <Input
            type="password"
            id="password-input"
            inputClassName="text-input"
            placeholder="podaj swoje hasło..."
            labelRef={setRef}
            label="Hasło"
            {...register("password", {
              required: true,
              pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{6,32}$/,
            })}
            onFocus={addLabelClass}
            onBlur={removeLabelClass}
          >
            <span className="validation-error-message">
              {errors.password && validationMessages.password}
            </span>
          </Input>
          <Input
            inputClassName="text-input"
            placeholder="powtórz hasło..."
            labelRef={setRef}
            label="Powtórz hasło"
            type="password"
            id="confirmPassword-input"
            {...register("confirmPassword", {
              required: true,
              validate: (value) => getValues("password") === value,
            })}
            onFocus={addLabelClass}
            onBlur={removeLabelClass}
          >
            <span className="validation-error-message">
              {errors.confirmPassword && validationMessages.confirmPassword}
            </span>
          </Input>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-9">
        <label className="plans-label">Pakiety</label>
        <div className="flex flex-col md:flex-row">
          <OptionCard
            value="basic"
            labelRef={setRef}
            {...register("plan", { required: true })}
            onChange={toggleCardClass}
            targetUrl="https://xsale.ai/cennik/"
          >
            <OptionCard.Content price={600} headerText="xSale">
              <OptionCard.Item>
                Integracja z nielimitowaną liczbą kanałów
              </OptionCard.Item>
              <OptionCard.Item>Bez limitu zamówień</OptionCard.Item>
            </OptionCard.Content>
          </OptionCard>
          <OptionCard
            value="light"
            labelRef={setRef}
            {...register("plan", { required: true })}
            onChange={toggleCardClass}
            targetUrl="https://xsale.ai/cennik/"
          >
            <OptionCard.Content price={100} headerText="xSale Light">
              <OptionCard.Item>Integracja z jednym sklepem</OptionCard.Item>
              <OptionCard.Item>Do 1500 zamówień miesięcznie</OptionCard.Item>
            </OptionCard.Content>
          </OptionCard>
        </div>
        <span className="validation-error-message">
          {errors.plan && validationMessages.plan}
        </span>
      </div>

      <Input
        labelRef={setRef}
        inputClassName="md:mr-2 md:order-first cursor-pointer mt-2"
        className="flex flex-row items-stretch justify-center flex-wrap mb-6 mt-9 w-5/6 mx-auto"
        labelClassName="text-center sm:text-left"
        label={() => (
          <TermsAgreementsLabel targetUrl="https://xsale.ai/regulamin-i-licencja-xsale/" />
        )}
        type="checkbox"
        id="termsAgreement-input"
        {...register("termsAgreement", {
          validate: (value) => value === "on",
        })}
        onChange={handleAgreementCheckboxChange}
      >
        <span className="validation-error-message w-full text-center">
          {errors.termsAgreement && validationMessages.termsAgreement}
        </span>
      </Input>

      <Button
        className="submit-button"
        submit
        id="submit-button"
        text="Potwierdź"
      />
    </form>
  );
});
