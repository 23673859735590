export default function Footer({ className, children }) {
  return (
    <footer
      className={`w-full bg-gray-200 flex p-2 justify-center items-center text-xs text-gray-500 text-center ${
        className ?? ""
      }`}
    >
      {children}
    </footer>
  );
}
