import {
  createContext,
  useCallback,
  useMemo,
  useContext,
  useReducer,
} from "react";

const layoutContext = createContext();

const defaultContent = Object.freeze({ footer: null, header: null });

export const useLayoutState = () => useContext(layoutContext);

export const LayoutProvider = ({
  children,
  initialContent = defaultContent,
} = {}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultContent,
    ...initialContent,
  });
  const setHeader = useCallback(
    (headerContent) =>
      dispatch({ type: ACTIONS.SET_HEADER, payload: { headerContent } }),
    []
  );
  const setFooter = useCallback(
    (footerContent) =>
      dispatch({ type: ACTIONS.SET_FOOTER, payload: { footerContent } }),
    []
  );
  const providerValue = useMemo(
    () => [state, { setHeader, setFooter }],
    [state, setFooter, setHeader]
  );
  return (
    <layoutContext.Provider value={providerValue}>
      {children}
    </layoutContext.Provider>
  );
};

const ACTIONS = Object.freeze({
  SET_HEADER: "setHeader",
  SET_FOOTER: "setFooter",
});

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_HEADER:
      return { ...state, header: action?.payload?.headerContent };
    case ACTIONS.SET_FOOTER:
      return { ...state, footer: action?.payload?.footerContent };
    default:
      return state;
  }
};
