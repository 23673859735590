import { css } from "@emotion/react";
import { BarLoader as Loader } from "react-spinners";

const override = css`
  display: block;
  background: rgb(0, 18, 242);
  background: linear-gradient(48deg, rgb(0, 0, 99) 30%, rgb(0, 18, 242) 100%);
`;

export default function InlineLoadingIndicator({ loading, className }) {
  return (
    loading && (
      <div
        className={`flex items-stretch justify-center w-full relative ${
          className ?? ""
        }`}
      >
        <Loader
          loading={loading}
          color="rgba(255, 255, 255,0.7)"
          css={override}
          size={60}
          height={12}
          width="100%"
        />
      </div>
    )
  );
}
