import { useState, useCallback, useEffect, useRef } from "react";
import { Redirect } from "react-router";
import InlineLoadingIndicator from "../../components/InlineLoadingIndicator";
import { useStore, STATUSES } from "../../contexts/store";
import { useLayoutState } from "../../contexts/layout";
import SignUpForm from "./SignUpForm";
import Card from "../../components/Card";
import api from "../../services/api";
import { ERROR_CODES } from "../../static/constants";

export default function SignUpPage() {
  const [{ authData, status }, actions] = useStore();
  const [, { setHeader }] = useLayoutState();
  const [isLoading, setIsLoading] = useState(false);
  const errorCodeRef = useRef();
  useEffect(() => {
    setHeader("Formularz rejestracji konta");
  }, []);
  const sendForm = useCallback(
    async (formData) => {
      setIsLoading(true);
      const [data, error] = await api.sendForm(formData, authData);
      setIsLoading(false);
      errorCodeRef.current = error?.errorCode;
      !!data?.isSuccess
        ? actions.success()
        : actions.fail({
            errors: {
              [error?.errorCode]: {
                message: ERROR_CODES[error?.errorCode] || ERROR_CODES.DEFAULT,
              },
            },
          });
    },
    [authData, actions]
  );
  const getRedirection = (key) => ({
    [STATUSES.SUCCESS]: (
      <Redirect push to={{ pathname: "/success", state: {} }} />
    ),
    [STATUSES.FAILURE]: (
      <Redirect push to={{ pathname: "/failure", state: { errorKey: key } }} />
    ),
  });

  return (
    getRedirection(errorCodeRef.current)[status] ?? (
      <>
        <Card className="min-h-4/5 sm:my-8 bg-card border border-gray-200">
          <SignUpForm sendForm={sendForm} />
          <InlineLoadingIndicator loading={isLoading} />
        </Card>
      </>
    )
  );
}
