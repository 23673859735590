import { useCallback, forwardRef } from "react";

export default forwardRef(({ className, id, text, onClick, submit }, ref) => {
  const click = useCallback(
    (e) => (typeof onClick === "function" ? onClick(e) : undefined),
    [onClick]
  );
  return (
    <button
      type={submit ? "submit" : "button"}
      ref={ref}
      onClick={click}
      id={id}
      className={` ${className}`}
    >
      {text}
    </button>
  );
});
